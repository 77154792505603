// import React from "react";
// import PageWrapper from "../components/PageWrapper";
// import Hero from "../sections/landing1/Hero";
// import Brands from "../sections/landing1/Brands";
// import Categories from "../sections/landing1/Categories";
// import Content1 from "../sections/landing1/Content1";
// import FeaturedJobs from "../sections/landing1/FeaturedJobs";
// import Content2 from "../sections/landing1/Content2";
// import RecommendedJobs from "../sections/landing1/RecommendedJobs";
// import RecentProfiles from "../sections/landing1/RecentProfiles";
// import RecommendedProfiles from "../sections/landing1/RecommendedProfiles";

// const IndexPage = () => {
//   return (
//     <>
//       <PageWrapper
//         headerConfig={{
//           bgClass: "dynamic-sticky-bg",
//         }}
//       >
//         <Hero />
//         <Brands />
//         <Categories />
//         <Content1 />
//         <FeaturedJobs />
//         <RecentProfiles />
//         <Content2 />
//         <RecommendedJobs />
//         <RecommendedProfiles />
//       </PageWrapper>
//     </>
//   );
// };
// export default IndexPage;

import React, { useContext } from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Brands from "../sections/landing1/Brands";
import Categories from "../sections/landing1/Categories";
import Content1 from "../sections/landing1/Content1";
import FeaturedJobs from "../sections/landing1/FeaturedJobs";
import Content2 from "../sections/landing1/Content2";
import RecommendedJobs from "../sections/landing1/RecommendedJobs";
import RecentProfiles from "../sections/landing1/RecentProfiles";
import RecommendedProfiles from "../sections/landing1/RecommendedProfiles";

import GlobalContext from "../context/GlobalContext";

const IndexPage = () => {
  const gContext = useContext(GlobalContext);

  // Define a caseKey based on conditions
  const caseKey = !gContext.token
    ? "unauthenticated"
    : gContext.isJobProvider === 1
      ? "jobProvider"
      : "jobSeeker";

  let content;

  // Use switch to handle different cases
  switch (caseKey) {
    case "jobProvider":
      content = (
        <PageWrapper
          headerConfig={{
            bgClass: "dynamic-sticky-bg",
          }}
        >
          <Hero />
          <RecentProfiles />
          {/* <Brands /> */}
          {/* <Categories /> */}
          <Content1 />
          {/* <FeaturedJobs /> */}
          {/* <RecommendedJobs /> */}
          <RecommendedProfiles />
          <Content2 />
        </PageWrapper>
      );
      break;

    case "jobSeeker":
      content = (
        <PageWrapper
          headerConfig={{
            bgClass: "dynamic-sticky-bg",
          }}
        >
          <Hero />
          <Brands />
          <Categories />
          <FeaturedJobs />
          <Content1 />
          <RecommendedJobs />
          {/* <Content2 /> */}
          {/* <RecentProfiles /> */}
          {/* <RecommendedProfiles /> */}
        </PageWrapper>
      );
      break;

    case "unauthenticated":
    default:
      content = (
        <PageWrapper
          headerConfig={{
            bgClass: "dynamic-sticky-bg",
          }}
        >
          <Hero />
          <Brands />
          <Categories />
          <FeaturedJobs />
          <Content1 />
          <RecommendedJobs />
          <Content2 />
          {/* <RecentProfiles /> */}
          {/* <RecommendedProfiles /> */}
        </PageWrapper>
      );
      break;
  }

  return content;
};

export default IndexPage;
