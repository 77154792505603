// import React, { useState, useEffect } from "react";
// import axiosConfig from '../../axiosConfig';


// const Brands = () => {
//   const [brands, setBrands] = useState([]);

//   useEffect(() => {
//     axiosConfig.get('/companies')
//       .then(response => {
//         if (response.data.success) {
//           console.log(response.data.data);
//           setBrands(response.data.data);
//         } else {
//           console.error('Failed to fetch brands');
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching brands:', error);
//       });
//   }, []);

//   return (
//     <>
//       {/* <!-- Brands Area --> */}
//       <div className="bg-black-2 dark-mode-texts pt-13 pt-lg-24 pb-12 pb-lg-23">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <div className="section-title mb-9 text-center text-lg-left">
//                 <h5 className="font-size-5 font-weight-normal">
//                   Get hired in top companies
//                 </h5>
//               </div>
//             </div>
//           </div>
//           <div className="row align-items-center justify-content-center justify-content-lg-between">
//             {brands.map((brand, index) => (
//               <div
//                 key={brand.id} // Ensure to use a unique key
//                 className="single-brand-logo mx-5 my-6"
//                 data-aos="fade-in"
//                 data-aos-duration="800"
//                 data-aos-delay={index * 200}
//               >
// <img src={'https://devadmin.stackle.in/images/' + brand.image} alt={brand.name} style={{ width: '200px' }} />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Brands;

import React, { useState, useEffect } from "react";
import axiosConfig from '../../axiosConfig';
import network from "../../../constants/Network";


const Brands = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    axiosConfig.get('/companies')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          setBrands(response.data.data);
        } else {
          console.error('Failed to fetch brands');
        }
      })
      .catch(error => {
        console.error('Error fetching brands:', error);
      });
  }, []);

  return (
    <>
      {/* <!-- Brands Area --> */}
      <div className="bg-black-2 dark-mode-texts pt-13 pt-lg-24 pb-12 pb-lg-23">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title mb-9 text-center text-lg-left">
                <h5 className="font-size-5 font-weight-normal">
                  Get hired in top companies
                </h5>
              </div>
            </div>
          </div>
          {/* <div className="row align-items-center justify-content-center">
            {brands.map((brand, index) => (
              <div
                key={brand.id} // Ensure to use a unique key
                className="single-brand-logo mx-5 my-6"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay={index * 200}
              >
                <img src={`${network.serverip}/images/` + brand.image} alt={brand.name} style={{ width: '200px' }} />
              </div>
            ))}
          </div> */}

          <div className="row align-items-center justify-content-start">
            {brands.map((brand, index) => (
              <div
                key={brand.id}
                className="col-6 col-sm-3 col-md-2"
                data-aos="fade-in"
                data-aos-duration="800"
                data-aos-delay={index * 200}
                style={{
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '2rem',
                }}
              >
                <img
                  src={`${network.serverip}/images/` + brand.image}
                  alt={brand.name}
                  style={{
                    width: '100%',
                    aspectRatio: '1 / 1',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  );
};

export default Brands;